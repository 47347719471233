.App {
  background-color: #323232;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-family: Poppins;
}

.main_items_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main_item {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 128px;
  height: 128px;
}

.qr_code {
  width: 256px;
  height: 256px;
  background-color: white;
  padding: 24px;
}

.downloader_btn {
  display: flex;
  flex-direction: row;
  color: black;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  background: white;
  border-radius: 24px;
  margin-top: 12px;
  overflow: hidden;
  gap: 12px;
  height: 32px;
  align-items: center;
  cursor: pointer;
}

.downloader_img {
  width: 32px;
  height: 32px;
}

.instructions {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.app_error {
  background-color: #323232;
  height: 100vh;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  font-family: Poppins;
}

.footer {
  background-color: white;
  width: 100%;
  color: #323232;
  justify-content: center;
  font-weight: 500;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1000px) {
  .main_items_container {
    display: flex;
    flex-direction: column;
  }

  .main_item {
    width: 75vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
  }

  .instructions {
    margin-top: 32px;
  }
}